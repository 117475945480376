<template>
    <div class="card">
    <div class="card-body">
        <div class="row">
        <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
                Show&nbsp;
                <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                ></b-form-select
                >&nbsp;entries
            </label>
            </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
            <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right"
            >
            <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                v-model="filter"
                type="search"
                class="form-control form-control-sm ml-2"
                ></b-form-input>
            </label>
            </div>
        </div>
        <!-- End search -->
        </div>
        <div class="table-responsive">
        <b-table
            :items="prime_users"
            :fields="fields"
            responsive="sm"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
        >
            <template v-slot:cell(action)="row">
                <div class="btn" @click="$emit('viewRewards',row.item.member_ref_no)">View
                    <!-- <i v-b-tooltip.hover
                    title="Send a feedback email"
                    style="cursor: pointer"
                    class="ri-mail-send-line font-size-20"
                    ></i> -->
                </div>
            </template>
        </b-table>
        </div>
        <div class="row">
        <div class="col">
            <div class="dataTables_pa ginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                ></b-pagination>
            </ul>
            </div>
        </div>
        </div>
    </div>
    </div>
</template>
<style scoped>
.ri-mail-send-line:hover {
    color: #1cbb8c;
}
</style>
<script>
import appConfig from "@/app.config";
export default {
    components: { },
    data() {
    return {
        prime_users: [],
        overallCount: false,
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 20, 50],
        filter: null,
        filterOn: [],
        sortBy: "total_views",
        sortDesc: true,
        fields: [
            { key: "fname", sortable: true, label: "First Name" },
            { key: "lname", sortable: true, label: "Last Name" },
            { key: "email", sortable: true, label: "Email" },
            { key: "member_ref_no", sortable: true, label: "Ref No." },
            { key: "action", sortable: false, label: "Action" },
        ],
    };
    },
    watch: {
    filters: {
        deep: true,
        handler() {
            this.getPrimeUsersList();
        },
    },
    },
    computed: {
    rows() {
        return this.prime_users.length;
    },
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async getPrimeUsersList() {
            try {
                const response = await this.$http.get(appConfig.api_base_url + "/users/fetch-prime-members");
                this.prime_users = response.data.users;
                this.totalRows = this.prime_users.length;
            } catch (err) {
                console.log(err);
            }
        },
    },
    mounted() {
        this.getPrimeUsersList();
    },
};
</script>
